.af-bannerbar {
    padding: 1em;
    background-color: $af-DarkBlue;
    color: $af-Cream;
}

.af-alertbar {
    padding: 1em;
    color: $af-DarkGrey;
    background-color: $af-AlertYellow;
}