@import "bbbBar";
@import "quoteBar";
@import "linkBar";
@import "aboutBar";
@import "copyrightBar";

.af-footer {
  width: 100%;
  background-color: $af-BackgroundGrey;
}
