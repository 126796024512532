.af-menubar {
  padding-top: 15px;
  padding-bottom: 15px;
  background: white;
  color: $af-LightGrey;

  a {
    text-decoration: none;
  }
}

.af-menu {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.af-hamburger {
  display: none;
  padding: 0em;
  height: 100%;
  &:focus, &:hover, &.active, button {
    box-shadow: none;
    border: none;
    outline: none;
  }
}

.af-menuitem {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-right: 1em;
  vertical-align: baseline;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 1em;
  white-space: nowrap;

  &:not(:first-of-type) + .af-menuitem {
    border-left: solid 2px $af-DarkGrey;
    @media screen and (min-width: 768px) {
      border-left: none;
    }
  }
  +.af-menuitem {
    padding-left: 0.5em;
    margin-left: 0.5em;
    @media screen and (min-width: 768px) {
      padding-left: 2em;
      margin-left: initial;
    }
  }

  span[class*="icon-"] {
    padding-right: 0em;
  }

  @media screen and (min-width: 576px) {
    flex-direction: row;
    text-align: left;
    span[class*="icon-"] {
      padding-right: 0.5em;
    }  
  }
}

.af-menuitem-text {
  height: 100%;
}

.af-staticmenu {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  margin-right: 1em;
  .af-menuitem {
    padding-right: 0em;
  }
  @media screen and (min-width: 576px) {
    margin-right: 0em;
  }
}

.af-policy {
  display: none;
  @media screen and (min-width: 576px) {
    display: flex;
  }
}
.af-policy-xs {
  @media screen and (min-width: 576px) {
    display: none;
  }
}

.af-phone-number {
  display: none;
  @media screen and (min-width: 576px) and (max-width: 768px) {
    display: initial;
  }
}

.af-phone-number-xs {
  @media screen and (min-width: 576px) {
    display: none;
  }
}

.af-home {
  display: none;
}

// Handles the collapsing of the menu into a drop-down.
// We'll need to adjust the width at which this occurs.
@media screen and (max-width: 1024px) {
  .af-home {
    display: flex;
    background-color: white;
    margin-left: 0em;
    border-left: none;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    text-align: left;
    border-bottom: 1px solid $af-ShadowGrey;
    z-index: 1000;
  }

  .af-menu {
    position: fixed;
    left: 0;
    top: 0;
    max-width: 360px;
    height: 100%;
    background: $af-Cream;
    flex-direction: column;
    display: none;
    margin-top: 0em;
    margin-left: 0em;
    z-index: 1000;
   .af-menuitem {
      background-color: white;
      margin-left: 0em;
      border-left: none;
      padding-top: 1em;
      padding-bottom: 1em;
      padding-left: 1em;
      text-align: left;
      border-bottom: 1px solid $af-ShadowGrey;
      z-index: 1000;
      +.af-menuitem {
        border-left: none;
      }
    }
  }

  .af-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $af-DarkGrey;
    opacity: 0.7;
    z-index: 999;
  }

  .af-hamburger {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: absolute;
    margin-left: 0em;
    border: 0px solid transparent;
    background: transparent;
    color: $af-MediumBlue;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1em;
    span[class*="icon-"] {
      padding-right: 0em;
    }
    @media screen and (min-width: 576px) {
      flex-direction: row;
      text-align: left;
      span[class*="icon-"] {
        padding-right: 0.5em;
        padding-bottom: 0.2em;
      }  
    }  
  }

  .af-hamburger[aria-expanded="true"] + .af-menu {
    display: flex;
  }
}