$size: 2em;
$outer-border: 1px;
$spinner-width: 4px;

.spinner-outer-border {
  display: inline-block;
  width: $size;
  height: $size;
  vertical-align: text-bottom;
  border: $outer-border solid rgba($af-DarkBlue, 0.25);
  border-radius: 50%;
}

.spinner-border {
  width: calc(#{$size} - calc(2 * #{$outer-border}));
  height: calc(#{$size} - calc(2 * #{$outer-border}));
  border: $spinner-width solid $af-White;
  border-right: $spinner-width solid $af-DarkBlue;
  border-radius: 50%;
  vertical-align: top;
  animation: spinner-border 1s linear infinite;
}

.spinner-inner-border {
  display: inline-block;
  width: calc(#{$size} - calc(2 * #{$outer-border}) - calc(2 * #{$spinner-width}));
  height: calc(#{$size} - calc(2 * #{$outer-border}) - calc(2 * #{$spinner-width}));
  border: $outer-border solid rgba($af-DarkBlue, 0.25);
  border-radius: 50%;
  vertical-align: top;
}
