ol,
ul {
  list-style-type: none;

  &.bullet,
  &.number {
    padding: 0 1em 1em;
    margin-left: 1em;

    & > li {
      margin-bottom: 1em;
    }

    ul,
    ol {
      padding: 1em 1em 0;
      margin-left: 1em;
    }
  }

  &.bullet {
    & > li {
      list-style-type: disc;

      & > li {
        list-style-type: circle;

        & > li {
          list-style-type: square;
        }
      }
    }
  }

  &.number {
      & > li {
        list-style-type: decimal;

        & > li {
          list-style-type: lower-alpha;

          & > li{
            list-style-type: lower-roman;
          }
        }
      }
  }

  &.in-line {
    & > li {
      display: inline-block;
    }
  }

  &.comma {
    display: inline;

    & > li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 0.3em;

        &:after {
          content: ',';
        }
      }
    }
  }

  &.piped {
    $_pipeSpacing: 1em;

    & > li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: $_pipeSpacing;

        &:after {
          content: '';
          border-color: inherit;
          border-right: 1px solid;
          margin-left: #{$_pipeSpacing + 0.25em};
        }
      }
    }

    &.stacked {
      $_pipeSpacing: 0.5em;

      & > li:not(:last-child) {
        margin-right: $_pipeSpacing;
        position: relative;
        padding-right: #{$_pipeSpacing + 0.25em};

        &:after {
          display: inline-block;
          position: absolute;
          height: 100%;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
    }

    &.narrow {
      $_pipeSpacing: 0.5em;

      & > li:not(:last-child) {
        margin-right: $_pipeSpacing;

        &:after {
          display: inline-block;
          height: 1em;
          vertical-align: middle;
          margin-left: #{$_pipeSpacing + 0.25em};
        }
      }
    }
  }
}

.rich-text {
  ol {
    @extend .number;
  }

  ul {
    @extend .bullet;
  }
}
