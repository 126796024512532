.af-about-bar {
  border-top: solid 1px $af-ShadowGrey;
  border-bottom: solid 1px $af-ShadowGrey;
  padding: 0;
  padding-top: 1em;

  h4 {
    font-size: 1em;
    margin-bottom: 0em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  li {
    padding-left: 0px;
    margin-right: 0.5em;
  }

  .btn {
    background-color: transparent;
    color: $af-MediumBlue;
    text-transform: none;
    padding-bottom: 0.05em;
  }

  .af-about-bar-social {
    font-size: 1.5em;
    line-height: 1em;
    white-space: nowrap;
    padding-top: 0.45em;

    li {
      background-color: $af-MediumBlue;
      color: $af-Cream;
      border-radius: 50%;
      padding-top: 0.25em;
      width: 1.75em;
      height: 1.75em;
      margin-right: 0.50em;
      [class*="icon-"]::after {
        color: white;
        font-size: 0.85em;
      }
      &:hover, &:focus {
        background-color: $af-FocusBlue;
      }
      &:active {
        background-color: $af-ActiveBlue !important;
      }

      a {
        text-decoration: none;
      }

      @media screen and (min-width: 768px) {
        padding-left: 0.45em;
        padding-right: 0.45em;
      }
    }

    li:last-of-type {
      margin-right: 0;
    }
  }
}
