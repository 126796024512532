//NOTE: Ideally we'd use @use here instead of @import, but @use appears to not yet be supported by the Sass version being used. Worthy of future investigation to see if we can get it working.
@import '../fonts/connect-icons';

[class*="icon-"]::after {
  @include Styles();
  font-size: 0.9em;
  vertical-align: middle;
}

@each $_name, $_glyph in $Map {
  .icon-#{$_name}::after {
    content: $_glyph;
  }
}

@font-face {
  font-family: $Font;
  font-style: normal;
  font-weight: 400;
  font-display: auto;

  $_sources: '';
  @each $_ext, $_format in (
    'eot': 'embedded-opentype',
    'woff2': 'woff2',
    'woff': 'woff',
    'ttf': 'truetype',
    'svg': 'svg'
  ) {
    $_file: File($_ext);

    @if ($_file) {
      $_suffix: '';

      @if ($_ext == 'eot') {
        src: url($_file);
        $_suffix: '?#iefix';
      }

      $_source: #{url('../fonts/#{$_file}#{$_suffix}') format('#{$_format}')};

      @if ($_sources == '') {
        $_sources: $_source;
      } @else {
        $_sources: join($_sources, $_source, comma);
      }
    }
  }
  src: $_sources; 
}
