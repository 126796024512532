/** Override Bootstrap Colors, Fonts, and Sizes **/
.form-control {
  height: 3em;
  padding: 0 10px 0 10px;
  font-size: 1em;
  color: $af-DarkGrey;
  box-sizing: border-box;
  border: 1px solid $af-LightGrey;
  border-radius: 2px;
  &:focus {
    border: 2px solid $af-MediumBlue;
    border-bottom: 4px solid $af-MediumBlue;
    box-shadow: none;
  }
  input {
    font-size: 1em;
  }
}

.form-control.is-invalid {
  border: 1px solid $af-ErrorRed;
  border-color: $af-ErrorRed !important;
  background-image: unset;
  &:focus {
    border: 2px solid $af-ErrorRed;
    border-bottom: 4px solid $af-ErrorRed;
    box-shadow: none;
  }
}

.btn {
  height: 3em;
}

/** Connect Specific Styling **/
form {
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    padding-right: 2em !important;
  }

  .select-wrapper {
    height: 3em;
    min-height: 3em;
    max-height: 3em;
    width: 100%;
    background-color: $af-Cream;
    border-radius: 2px;
    position: relative;
  }

  .select-wrapper:after {
    content: '\ea03'; // chevron-down
    font: normal normal normal 12px/1 connect-icons;
    color: $af-DarkBlue;
    right: 0.8em;
    top: 0.5em;
    height: calc(3em - 4px);
    padding: 1em 0px 0px 0.5em;
    position: absolute;
    pointer-events: none;
  }
}

.af-groupheader {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    color: $af-DarkBlue;
    padding-bottom: 0.25em;  
}

.af-grid {
  display: grid;
  column-gap: 1em;
  row-gap: 0.25em;
}

.af-invalid {
  color: $af-ErrorRed;
}

.af-invalid-feedback {
  color: $af-ErrorRed;
  background: transparent;
  font-size: 70%;
  padding-top: 0.25em;
  padding-left: 0.25em;
}

.af-invalid-control + .custom-control-label::before {
  border-color: $af-ErrorRed;
}

.af-floating-label-group {
  margin-bottom: 0em;
  background-color: inherit;
  height: 4.5em;

  &.inline {    
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  .form-control {
    padding-top: 1em;
  }

  .af-floating-label {
    transform: translateX(0.25em);
    font-weight: normal;
    font-size: 80%;
    color: $af-LightGrey;
    z-index: 2;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 0.5em;
    pointer-events: none;
  }
  
  .af-floating-placeholder {
    transform: translateX(0.75em);
    font-weight: normal;
    color: $af-LightGrey;
    z-index: 2;
    width: calc(100% - 2em);
    height: 2.25em;
    padding-top: 0.75em;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: text;
    transition: all .1s linear;
    pointer-events: none;
  }

  &:focus-within {
    .af-floating-placeholder {
      height: calc(3em - 2px);
      padding-top: 0em;
      font-size: 80%;
      color: $af-MediumBlue;
    }    
    .af-floating-label {
      color: $af-MediumBlue;
    }
  }

  .af-floating-div {
    transform: translateY(-3em);
    background: transparent;
    position: relative;
    pointer-events: none;
    z-index: 3;
  }

  .af-floating-label-control {
    z-index: 1;
  }  

  .af-invalid-feedback {
    padding-top: 0px;
    width: calc(100% - 1em);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all .1s linear;
  }
}

.btn-invalid {
  box-shadow: 0 0 5px 2px $af-ErrorRed;
  color: darken($af-Cream, 10%);
  background-color: lighten($af-MediumBlue, 10%);
  border-color: lighten($af-MediumBlue, 10%);
  &:hover {
    color: darken($af-Cream, 10%);
    background-color: lighten($af-MediumBlue, 10%);
    border-color: lighten($af-MediumBlue, 10%);  
  }
}

$spinner-size: 1.25em;
$spinner-outer-border: 1px;
$spinner-width: 3px;

.af-form-spinner-outer-border {
  display: inline-block;
  width: $spinner-size;
  height: $spinner-size;
  vertical-align: text-bottom;
  border: $spinner-outer-border solid rgba($af-DarkBlue, 0.25);
  border-radius: 50%;
}

.af-form-spinner-border {
  width: calc(#{$spinner-size} - calc(2 * #{$spinner-outer-border}));
  height: calc(#{$spinner-size} - calc(2 * #{$spinner-outer-border}));
  border: $spinner-width solid $af-White;
  border-right: $spinner-width solid $af-DarkBlue;
  border-radius: 50%;
  vertical-align: top;
  animation: spinner-border 1s linear infinite;
}

.af-form-spinner-inner-border {
  display: inline-block;
  width: calc(#{$spinner-size} - calc(2 * #{$spinner-outer-border}) - calc(2 * #{$spinner-width}));
  height: calc(#{$spinner-size} - calc(2 * #{$spinner-outer-border}) - calc(2 * #{$spinner-width}));
  border: $spinner-outer-border solid rgba($af-DarkBlue, 0.25);
  border-radius: 50%;
  vertical-align: top;
}
