.af-quote-bar {
  background-color: $af-LightGrey;
  padding-top: 3em;
  padding-bottom: 3em;
  width: 100%;

  a {
    color: $af-Cream;
    font-weight: bold;
  }
}

.af-quote-form {
  color: $af-Cream;

  h4 {
    text-align: center;
    color: $af-Cream;
  }

  .form-control {
    border: 1px solid $af-MediumBlue;
    border-radius: 2px;
  }

  .btn {
    background-color: $af-White;
    color: $af-MediumBlue;
    margin-left: 0.25em;
    width: calc(100% - 0.5em);
    white-space: nowrap;
    border: solid 1px $af-LightGrey;
    &:hover, &:focus {
      background-color: $af-Cream;
    }

    @media screen and (min-width: 576px) {
      width: 100%;      
    }
  }

  select {
    min-width: 200px;
  }

  .af-floating-label-group {
    .af-invalid-feedback {
      color: $af-Cream;
    }
  }
}

.af-retrieve-bar {
  margin: 0px !important;
  padding: 0px;
}