.af-link-bar {
  padding-top: 1em;
  padding-bottom: 1em;

  .af-link-groups {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(6, auto);
    text-align: center;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, auto);
      text-align: left;
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: auto;
      text-align: left;
    }
  }

  .af-link-group {
    margin-top: 0.5em;
    padding-top: 0.5em;
    ul {
      display: none;
      @media screen and (min-width: 768px) {
        display: initial;
      }  
    }
  }

  h4 {
    font-size: 1em;
    a {
      font-weight: 600;
    }
  }

  ul {
    padding: 0px;
    margin: 0px;
  }

  li {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal !important;
    margin-bottom: 0.5em;
  }

  a {
    font-weight: 400;
  }
}